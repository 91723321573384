.app-footer{
    color: #ffffff;
    background-color: #4278b7;
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;
    width: 100%;
    bottom: 0px;
    padding: 0 50px;
    justify-content: space-between;
}

#hithsaLogo{
    width: 40%;
}

/* .footer-left{
    margin-left: 60%;
}

.footer-right{
    margin-left: 10%;
} */

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {
    .footer-left{
        margin-left: 40%;
    }

    #hithsaLogo{
        margin-left: 100px;
    }
}