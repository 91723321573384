.btn-group {
    /* margin-left: 80%; */
    float: right;
    margin-bottom: 10px;
}

.btnSave{
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}

.form-details{
    padding: 0 10px;
}

.page-header{
    background-color: #ffffff;
    height: 50px;
    align-items: center;
}

.body-content{
    /* border-top: 2px solid #4278b7; */
    margin-top: 5px;
    background-color: #ffffff;
}

.body-content h5{
    margin: 10px;
    padding: 5px;
    font-size: 1.1rem;
}

.filter-form h5{
    margin-bottom: 20px;
}

.filter-form{
    border-top: 2px solid #d2d6de;
    border-bottom: 2px solid #d2d6de;
    margin: 10px 0;
    padding: 10px;
    margin-bottom: 10px;
}

.profile-content{
    /* border-top: 2px solid #d2d6de; */
    padding: 5px 0;
    background-color: #ffffff;
}

.profile-content h5{
    margin: 10px;
    padding: 5px;
    font-size: 1.1rem;
}

.filter-btns{
    display: flex;
    justify-content: flex-end;
}

.filter-header{
    display: flex;
    justify-content: space-between;
}

.filter-header h5{
    margin-left: -10px;
    color: #1873E0;
    font-size: 1rem;
}

.table-content{
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    display: block;
}

.btn-close {
    cursor: pointer;
}

#table-icon{
    color:#4278b7; 
    width:1.5rem;
    height: 1.5rem;
    padding:3px;
    margin: 3px 0;
}

#table-icon-disabled{
    color:#4278b7; 
    width:1.5rem;
    height: 1.5rem;
    padding:3px;
    margin: 3px 0;
}

#table-icon-disabled:hover{
    cursor: not-allowed;
}

#account-icon{
    color:#4278b7; 
    width:1.5rem;
    height: 1.5rem;
    padding:3px;
    margin: 3px 5px;
}

#account-icon-color{
    color:#ff555d; 
    width:1.5rem;
    height: 1.5rem;
    font-weight: 700;
    padding:3px;
    margin: 3px 5px;
    border-radius: 10px;
}

#account-icon-color:hover{
    cursor: pointer;
}

#add-activity{
    width:2rem;
    height: 2rem;
    padding:3px;
    /* border:2px solid #4278b7; */
    margin: 3px 0;
    color: #4278b7;
}

#email-icon{
    width: 80px;
    color: #f5a423;
    padding: 2px;
    margin: 20px;
}


.add-icon {
    display: flex;
    justify-content: flex-end;
}

.add-icon :hover{
    cursor: pointer;
}

.add-icon-disabled {
    display: flex;
    justify-content: flex-end;
}

.add-icon-disabled :hover{
    cursor: not-allowed;
}

#tab-icon{
    color:#4278b7;
    width:1.5rem;
    padding:2px;
}

#table-icon:hover{
    cursor: pointer;
}

#upDown-icon{
    color:#4278b7;
    width: 1rem;
}

#upDown-icon:hover{
    cursor: pointer;
}

#header-icon{
    width: 1.8rem;
    color: #4278b7;
}

.header-text{
    margin-left: 5px;
    color: #4278b7;
    font-weight: 700;

}

.userPhoto{
    text-align: center;
}

.imageView{
    width:150px;
    border:1px solid #4278b7;
    border-radius: 5px;
    margin: 5px;
    margin-bottom: 10px;
    padding: 15px;
}

.correspondenceTab{
    /* display: flex;
    justify-content: center; */
    margin: 20px;
    padding: 10px;
    border:1px solid #dbd8d8;
    width: 70%;
}

#banner{
    width: 100%;
    height: 50vh;
}

#profile_logo{
    /* padding: 20px; */
    margin: 10px;
    width: 100%;
}

.service-content {
    display: flex;
    margin: 3%;
}

.card-content{
    margin: 0 5%;
}

.card-content h4{
    color:#000000;
    font-size: 1.3rem;
}

.card-content span{
    color: #555f67;
    text-align: left;
    margin-right: 5%;
}

.location{
    display: flex;
}

#location-icon{
    width: 1.8rem;
    color: #ee0a0a;
    margin-right: 20px;
}

.right-card{
    display: flex;
    margin: 2%;
    border-left: 2px solid #808080;
    padding-left: 2%;
    width: 50%;
}

.service-list{
    display: grid;
    margin-bottom: 7%;
    grid-template-columns: repeat(2, 1fr);
    /* flex-direction: row; */
    justify-content: space-around;
    flex-flow: wrap;
    margin: 3% 0;
}

.area-list{
    display: grid;
    margin-bottom: 7%;
    grid-template-columns: repeat(4, 1fr);
    /* flex-direction: row; */
    justify-content: space-around;
    flex-flow: wrap;
    margin-top: 3%;
}
/* 
@media screen and (max-width: 1500px){ 
    .service-list{
        margin: 2%;
    }
} */

.profile-details{
    border:1px solid #becddf;
    border-radius:5px;
    margin-top:10px;
    padding:20px
}

.profile-businessName{
    font-size: 1.3rem;
    font-weight: 600;
    margin: 5px;
}

.product-type{
    display: flex;
    border-bottom: 1px solid #dadada;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.product-type-text{
    font-size: 1.2rem;
    padding: 10px;
}

#product-icon{
    width: 3rem;
    height: 3rem;
    font-weight: 600;
    padding: 10px;
    border-radius: 50%;
    background-color: #1897e0;
    color: #ffffff;
}

/* .custom-control-label::before {
    background-color: #bbbbbb;
    width: 2.8rem;
    height: 1.5rem;
}

.custom-switch .custom-control-label::after {
    background-color: #ffffff;
    width: 1.5rem;
    height: 1.5rem;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: #28a745;
    width: 2.8rem;
    height: 1.5rem;
} */

.account-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* flex-direction: row; */
    justify-content: space-between;
    flex-flow: wrap;
    margin: 10px;
}

.account_system{
    /* margin-bottom: 50px;
    padding: 15px;
    border: solid 1px #a5a5a5; */
    margin: 10px;
    padding: 15px;
    text-align: left;
    width: 100%;
    transition: 0.2s;
    box-shadow: 5px 5px 30px -10px #c7c5c5;
    border-radius: 10px;
    border: solid 1px #e0e0e0; 
    margin-left: auto;
}

.systemName{
    text-align:left;
    font-size: 1.1rem;
    font-weight: 600;
    color: #4278b7;
}

.switch-button{
    display: flex;
    justify-content: flex-end;
}

.system-description{
    margin: 5px 10px 5px 15px;
    text-align: justify;
}

.system-date{
    margin: 10px 10px 5px 15px;
    display: flex;
    justify-content: space-between;
}

#acc_sys_logo_xero{
    width: 70px;
}

#acc_sys_logo_quickBooks{
    width: 80px;
}

#acc_sys_logo_myob{
    width: 70px;
}



@media screen and (max-width: 1000px){
    .service-list{
        grid-template-columns: repeat(1, 1fr);
        margin: 1%;
    }

    .area-list{
        grid-template-columns: repeat(2, 1fr);
        margin: 1%;
    }
}

@media screen and (max-width: 480px) {
    .service-content {
        display: inline;
        margin: 1%;
    }

    .left-card{
        width: 100%;
        border-left: none;
        border-bottom: 2px solid #a5a5a5;
        padding-bottom: 2%;
    }

    .right-card{
        width: 100%;
        border-left: none;
        border-bottom: 2px solid #a5a5a5;
        padding: 2%;
    }
}