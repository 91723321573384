table {
  border-collapse: collapse;
  /* border-spacing: 0 0.5em; */
  width: 100%;
  padding: 10px;
}

table td {
  border: 2px solid #f7f7f7;
  padding: 5px;
  text-align: left;
  /* background-color: #ffffff; */
}

/* table td { */
/* border-radius: 5px; */

/* } */

.tRow {
  background-color: #ffffff;
  cursor: pointer;
}

.tSelectedRow {
  background-color: #e9e9e9;
  cursor: pointer;
}

table tr:hover {
  background-color: #e9e9e9;
}

table th {
  padding: 15px 5px 15px 5px;
  text-align: left;
  background-color: #ececec;
  color: #646464;
  border: 1px solid #ffffff;
}

.modal_width {
  min-width: 70%;
}

.pdf_modal_width {
  width: 90vw
}

/* .tableResponsive {
    overflow-x: auto;
  } */

#btn-icon {
  width: 1.2rem;
  color: #000000;
  padding: 2px;
}

#message-warning {
  color: #f5a423;
  margin-right: 10px;
}

#message-delete {
  color: #bb2124;
  margin-right: 10px;
}

/* .body{
  background-color: #ffffff;
} */

/* .loader{
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  width: 100%;
  height: 10px;
  text-align: center;
}

.loader span {
  width: 8px;
  height: 8px;
  margin: 2px;
  display: inline-block;
  border-radius: 50%;
  animation: animate 2s linear infinite;
  opacity: 0;
}

.loader span:nth-child(1){
  animation-delay: 0.8s;
  background-color: #6ea5e9;
}

.loader span:nth-child(2){
  animation-delay: 0.6s;
  background-color: #2062b3;
}

.loader span:nth-child(3){
  animation-delay: 0.4s;
  background-color: #315683;
}

.loader span:nth-child(4){
  animation-delay: 0.2s;
  background-color: #2062b3;
}

.loader span:nth-child(5){
  animation-delay: 0s;
  background-color: #6ea5e9;
} */

/* .loader{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8%;
}

.svg{
  position:relative;
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate 
{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

.svg circle{
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 4;
  stroke: #00a1ff;
  stroke-linecap: round;
  transform: translate(5px, 5px);
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
  animation: animate 3s linear infinite;
}

@keyframes animate 
{
  0%, 100%{
    stroke-dashoffset: 110;
  }
  50%{
    stroke-dashoffset: 0;
  }
  
  50.1%{
    stroke-dashoffset: 220;
  }
} */


#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #4278b7;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.pagination {
  color: #4278b7;
  background-color: #ffffff;
  /* border-top: 1px solid #979797; */
  display: flex;
  align-items: center;
  height: 40px;
  position: static;
  /* position: fixed; */
  width: 100%;
  bottom: 50px;
  justify-content: space-between;
  /* padding-right: 17%; */
  padding-right: 10px;
}

.pagination-details {
  margin-bottom: 2px;
}

.pagination-details span {
  margin: 0 10px;
  cursor: pointer;
}

#pagination-icon {
  color: #4278b7;
  cursor: pointer;
  padding: 1px;
  padding-top: 4px;
}

.active-page {
  font-weight: 1000;
}

.datePicker {
  width: 100%;
}

/* @keyframes animate{
  0%{
    transform: translateX(-200px);
    opacity: 0;
  }

  25%{
    transform: translateX(0);
    opacity: 1;
  }

  50%{
    transform: translateX(0);
    opacity: 1;
  }

  75%{
    transform: translateX(0);
    opacity: 1;
  }

  100%{
    transform: translateX(100px);
    opacity: 0;
  }

  90%{
    transform: translateX(100px);
    opacity: 0;
  }
} */

/* .loaderlogo{
  position: absolute;
} */


.errorAlert {
  background: #f8d7da;
  padding: 20px 40px;
  min-width: 420px;
  position: absolute;
  right: 0px;
  top: 60px;
  border-radius: 10px 0 0 10px;
  border-left: 8px solid #e41728;
  overflow: hidden;
  z-index: 1;
}

.errorAlert .errorHide {
  display: none;
}

#message-error {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #f80217;
  font-size: 10px
}

.errorMassage {
  padding: 0 20px;
  font-size: 18px;
  color: #f80217
}

.errorCloseBtn {
  position: absolute;
  right: 0px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  background: #ee071a;
  padding: 20px 10px
}

.errorCloseBtn:hover {
  background: #e72d3c;
}

#decline {
  color: #ffffff;
  font-size: 22px;
  line-height: 40px
}

.card {
  /* margin: 5px; */
  /* padding: 5px; */
  text-align: left;
  width: 100%;
  transition: 0.2s;
  /* border-radius: 10px; */
  margin-left: auto;
  border: 1px solid #969696;
  box-shadow: 0 7px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.card:hover {
  transform: scale(1.01);
  box-shadow: 10px 10px 30px -5px rgba(0, 0, 0, 0.18);
}

#card-icon {
  width: 3rem;
  height: 3rem;
  color: #f5a423;
  padding: 2px;
  margin: 20px;
}

.card-top {
  display: flex;
  justify-content: space-between;
}

.card-top span {
  padding-top: 10px;
  color: #0062ca;
  font-size: 16px;
  font-weight: 600;
}

.card-bottom {
  display: flex;
  justify-content: space-between;
}

.card-bottom span {
  padding-top: 10px;
  color: #007bff;
}

.dot {
  text-align: center;
  margin-top: -27px;
}

.dot span {
  height: 85px;
  width: 85px;
  background-color: #ffa534;
  border-radius: 50%;
  display: inline-block;
}

.button:disabled {
  cursor: not-allowed !important;
}

.button:enabled {
  cursor: pointer;
}


.email-viewer {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.email-editor {
  margin: 5px;
  padding: 5px;
  width: 50%;
  border: 1px solid #dbd8d8;
  border-radius: 5px;
}

.email-body {
  margin: 5px;
  padding: 5px;
  width: 50%;
  border: 1px solid #dbd8d8;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .card {
    margin: 5px 1px;
    padding: 0;
  }
}


@media screen and (max-width: 820px) {
  .email-viewer {
    display: inline;
  }

  .email-editor {
    width: 100%;
  }

  .email-body {
    width: 100%;
  }
}