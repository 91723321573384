.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App{
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .sales{
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.dashboard{
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
} */

/* .profile{
  margin-left: 14%;
  padding: 10px;
} */

.content-full-sidebar {
  margin-left: 14%;
  padding: 10px;
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  background-color:#f7f7f7;
}

.content-icon-sidebar {
  margin-left: 4%;
  padding: 10px;
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  background-color:#f7f7f7;
}